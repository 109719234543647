import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import GUI from 'lil-gui'



/**
 * Base
 */
// Debug
const gui = new GUI()

let theta = 0
const radius = 5



// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

//Axes helper 
// const axesHelper = new THREE.AxesHelper()
// scene.add(axesHelper)

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('/textures/matcaps/2.png')
matcapTexture.colorSpace = THREE.SRGBColorSpace

/**
 * fonts
 */
const fontLoader = new FontLoader()
fontLoader.load('/fonts/helvetiker_regular.typeface.json',
(font) =>
{
    
    const textGeometry = new TextGeometry(
        'Fructox',
        {
            font: font,
            size: 0.5,
            height: 0.2,
            curveSegments: 3,
            bevelEnabled: true,
            bevelThickness: 0.03,
            bevelSize: 0.02,
            bevelOffset: 0,
            bevelSegments: 3
        }
    )
    // textGeometry.computeBoundingBox()
    // textGeometry.translate(
    //    - (textGeometry.boundingBox.max.x - 0.2) * 0.5,
    //    - (textGeometry.boundingBox.max.y - 0.2) * 0.5,
    //    - (textGeometry.boundingBox.max.z - 0.03) * 0.5,

    // )

    textGeometry.center()

    const normal = new THREE.MeshNormalMaterial()
   
    const material = new THREE.MeshMatcapMaterial()
    material.matcap = matcapTexture
    const text = new THREE.Mesh(textGeometry, normal)
    const donutGeometry = new THREE.TorusGeometry(0.3,0.2,20,45)
    material.matcap = matcapTexture
    const boxGeometry = new THREE.BoxGeometry()
    for(let i = 0; i < 50; i++){
        
        //donut
        const donut = new THREE.Mesh(donutGeometry, normal)
        donut.position.x = (Math.random() - 0.5)* 10 
        donut.position.y = (Math.random() - 0.5)* 10 
        donut.position.z = (Math.random() - 0.5)* 10 

        donut.rotation.x = Math.random() * Math.PI
        donut.rotation.y = Math.random() * Math.PI

        const box = new THREE.Mesh(boxGeometry, normal)
        box.position.x = (Math.random() - 0.5)* 10 
        box.position.y = (Math.random() - 0.5)* 10 
        box.position.z = (Math.random() - 0.5)* 10 

        box.rotation.x = Math.random() * Math.PI
        box.rotation.y = Math.random() * Math.PI

        

        const scale = Math.random()
        donut.scale.set(scale, scale, scale)
        box.scale.set(0.2, 0.2, 0.2)
        scene.add(donut)
        scene.add(box)
    }
    scene.add(text)
})


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 1
camera.position.y = 1
camera.position.z = 2


scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    theta += 0.1;

	camera.position.x = radius * Math.sin( THREE.MathUtils.degToRad( theta ) );
	camera.position.y = radius * Math.sin( THREE.MathUtils.degToRad( theta ) );
	// camera.position.z = radius * Math.cos( THREE.MathUtils.degToRad( theta ) );
	camera.lookAt( scene.position );

	// camera.updateMatrixWorld();


    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()